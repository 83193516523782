/* Estilos Gerais do Formulário */
.form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f9f9f9;
}

.form {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
  transition: all 0.3s ease-in-out;
}

.form .input-group {
  margin-bottom: 15px;
}

.form label {
  font-weight: bold;
  margin-bottom: 5px;
  font-size: 14px;
  color: #333;
}

.form input, .form textarea {
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ddd;
  font-size: 14px;
  background-color: #f4f4f4;
  transition: border 0.3s ease;
}

.form input:focus, .form textarea:focus {
  border-color: #6c5ce7;
  background-color: #fff;
  outline: none;
}

.form button {
  background-color: #00761F;
  color: white;
  font-size: 16px;
  padding: 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  transition: background-color 0.3s ease;
}

.form button:hover {
  background-color: #ffffff;
  color: #00761F;
  border: 1px solid #00761F;
}

/* Estilos do Popup */
.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 300px;
  text-align: center;
  z-index: 1000;
  opacity: 1;
  transition: opacity 0.3s ease-out;
}

.popup.success {
  border-left: 5px solid green;
}

.popup.error {
  border-left: 5px solid red;
}

.popup .icon {
  font-size: 30px;
  margin-right: 10px;
}

.popup p {
  font-size: 16px;
  color: #333;
}

.popup.success {
  background-color: #d4edda;
  color: green;
}

.popup.error {
  background-color: #f8d7da;
  color: red;
}
